import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dangerous-goods-dialog',
  templateUrl: './dangerous-goods-dialog.component.html',
  styleUrls: ['./dangerous-goods-dialog.component.scss']
})
export class DangerousGoodsDialogComponent {
  group: FormGroup = new FormGroup({
    dangerous_goods_class: new FormControl(null, [Validators.required, Validators.pattern(/[0-9]/)]),
    packaging_group: new FormControl(null, [Validators.required, Validators.pattern(/[0-9]/)]),
    un_number: new FormControl(null, [Validators.required, Validators.pattern(/[0-9]/)])
  });

  constructor(private _ref: MatDialogRef<DangerousGoodsDialogComponent>) { }

  ngOnInit() {}

  save() {
    this._ref.close(this.group.value);
  }
}
