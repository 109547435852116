import { NgModule } from '@angular/core';
import { KeeniconComponent } from './keenicon/keenicon.component';
import { CommonModule } from "@angular/common";
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { StatusHelperModule } from './status.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AddressSummaryComponent } from 'src/app/modules/shared/address-summary/address-summary.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LoadingKindSummaryComponent } from 'src/app/modules/shared/loading-kind-summary/loading-kind-summary.component';
import { CratesSummaryComponent } from 'src/app/modules/shared/crates-summary/crates-summary.component';
import { VehicleSummaryComponent } from 'src/app/modules/shared/vehicle-summary/vehicle-summary.component';
import { MatIconModule } from '@angular/material/icon';
import { LoadingMeterSummaryComponent } from 'src/app/modules/shared/loading-meter-summary/loading-meter-summary.component';
import { AdditionalOptionsSummaryComponent } from 'src/app/modules/shared/additional-options-summary/additional-options-summary.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  declarations: [
    KeeniconComponent,
    AddressSummaryComponent,
    LoadingKindSummaryComponent,
    CratesSummaryComponent,
    LoadingMeterSummaryComponent,
    VehicleSummaryComponent,
    AdditionalOptionsSummaryComponent
  ],
  imports: [
    CommonModule,
    NgxMatIntlTelInputComponent,
    StatusHelperModule,
    NgApexchartsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatRadioModule,
    MatDatepickerModule,

  ],
  exports: [
    KeeniconComponent,
    NgxMatIntlTelInputComponent,
    StatusHelperModule,
    NgApexchartsModule,
    AddressSummaryComponent,
    LoadingKindSummaryComponent,
    CratesSummaryComponent,
    LoadingMeterSummaryComponent,
    VehicleSummaryComponent,
    AdditionalOptionsSummaryComponent,
    ReactiveFormsModule,

  ]
})
export class SharedModule {
}
