import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import dayjs from 'dayjs';
import { BehaviorSubject } from 'rxjs';
import { StatusHelperModule } from 'src/app/_metronic/shared/status.module';
import { countryListAllIsoData } from 'src/app/data/countries';

@Component({
  selector: 'app-address-summary',
  templateUrl: './address-summary.component.html',
  styleUrls: ['./address-summary.component.scss']
})
export class AddressSummaryComponent {
  constructor(
    public _status: StatusHelperModule) {}

  countryList = countryListAllIsoData;
  isLoading = new BehaviorSubject(true);
  orderId: string;
  data: any = {};
  dayjs = dayjs;

  @Input()
  group: FormGroup;
}
