<form [formGroup]="group">
  <form formGroupName="loading_meter">
    <div class="row">
      <div class="col-6">
        <div class="fv-row mb-3">
          <label class="form-label fs-6 fw-bolder text-dark">
            <span class="required">Lademeter</span>
          </label>
          <input class="form-control bg-transparent" type="number" formControlName="loading_meter" autocomplete="off" />
        </div>
      </div>
  
      <div class="col-6">
        <div class="fv-row mb-3">
          <label class="form-label fs-6 fw-bolder text-dark">
            <span class="required">Gesamtgewicht</span>
          </label>
          <input class="form-control bg-transparent" type="number" formControlName="weight" autocomplete="off" />
        </div>
      </div>
    </div>
  </form>
  
</form>
