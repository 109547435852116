<h1 mat-dialog-title>Gefahrgut Daten</h1>
<div mat-dialog-content>
  <form [formGroup]="group">
    <mat-form-field class="w-100 mb-3 pt-2" appearance="outline">
      <mat-label>Gefahrgutklasse</mat-label>
      <input matInput type="number" formControlName="dangerous_goods_class" />
      <mat-error>
        <span>Ungültige Gefahrgutklasse</span>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-100 mb-3" appearance="outline">
      <mat-label>Verpackungsgruppe</mat-label>
      <input matInput type="number" formControlName="packaging_group" />
      <mat-error>
        <span>Ungültige Verpackungsgruppe</span>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-100 mb-3" appearance="outline">
      <mat-label>UN Nummer</mat-label>
      <input matInput type="number" formControlName="un_number" />
      <mat-error>
        <span>Ungültige UN Nummer</span>
      </mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close] cdkFocusInitial>
    <span>Abbrechen</span>
  </button>
  <button mat-button [disabled]="group.invalid" (click)="save()" cdkFocusInitial>Speichern</button>
</div>
