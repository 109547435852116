<form [formGroup]="group">
  <div formGroupName="additional_information">
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="row">
          <div class="col-7">
            <div class="fv-row mb-8 position-relative">
              <label class="required">Abholdatum von</label>
              <input class="form-control bg-transparent" type="text" formControlName="pickup_start_date"
                autocomplete="off" [matDatepicker]="pickupStartDate" />
              <div class="input-group-suffix">
                <mat-datepicker-toggle [for]="pickupStartDate" style="margin-top: -8px"></mat-datepicker-toggle>
                <mat-datepicker #pickupStartDate></mat-datepicker>
              </div>
            </div>
          </div>
          <div class="col-4" *ngIf="!group.get('product')?.value?.endsWith('Direct')">
            <div class="fv-row mb-3">
              <label class="required">
                Abholuhrzeit von
              </label>
              <select class="form-select bg-transparent" data-control="select2" data-hide-search="true"
                formControlName="pickup_start_time">
                <option *ngFor="let slot of getTimeslots()" [value]="slot">{{ slot }}</option>
              </select>
            </div>
          </div>
          <div class="col-4" *ngIf="group.get('product')?.value?.endsWith('Direct')">
            <div class="fv-row mb-3">
              <label class="required">
                Abholuhrzeit
              </label>
              <select class="form-select bg-transparent" data-control="select2" data-hide-search="true"
                formControlName="start_time">
                <option *ngFor="let slot of getDirectTimeslots()" [value]="slot">{{ slot }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="row">
          <div class="col-7">
            <div class="fv-row mb-8 position-relative">
              <label class="required">Abholdatum bis</label>
              <input class="form-control bg-transparent" type="text" formControlName="pickup_end_date"
                autocomplete="off" [matDatepicker]="pickupEndDate" />
              <div class="input-group-suffix">
                <mat-datepicker-toggle [for]="pickupEndDate"></mat-datepicker-toggle>
                <mat-datepicker #pickupEndDate></mat-datepicker>
              </div>
            </div>
          </div>
          <div class="col-4" *ngIf="!group.get('product')?.value?.endsWith('Direct')">
            <div class="fv-row mb-3">
              <label class="required">
                Abholuhrzeit bis
              </label>
              <select class="form-select bg-transparent" data-control="select2" data-hide-search="true"
                formControlName="pickup_end_time">
                <option *ngFor="let slot of getTimeslots()" [value]="slot">{{ slot }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="row">
          <div class="col-7">
            <div class="fv-row mb-8 position-relative">
              <label class="required">Zustelldatum von</label>
              <input class="form-control bg-transparent" type="text" formControlName="deliver_start_date"
                autocomplete="off" [matDatepicker]="deliverStartDate" />
              <div class="input-group-suffix">
                <mat-datepicker-toggle [for]="deliverStartDate"></mat-datepicker-toggle>
                <mat-datepicker #deliverStartDate></mat-datepicker>
              </div>
            </div>
          </div>
          <div class="col-4" *ngIf="group.get('product')?.value?.endsWith('Direct')">
            <div class="fv-row mb-3">
              <label class="required">
                Zustelluhrzeit
              </label>
              <select class="form-select bg-transparent" data-control="select2" data-hide-search="true"
                formControlName="end_time">
                <option *ngFor="let slot of getDirectTimeslots()" [value]="slot">{{ slot }}</option>
              </select>
            </div>
          </div>
          <div class="col-4" *ngIf="!group.get('product')?.value?.endsWith('Direct')">
            <div class="fv-row mb-3">
              <label class="required">
                Zustelluhrzeit von
              </label>
              <select class="form-select bg-transparent" data-control="select2" data-hide-search="true"
                formControlName="deliver_start_time">
                <option *ngFor="let slot of getTimeslots()" [value]="slot">{{ slot }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="row">
          <div class="col-7">
            <div class="fv-row mb-8 position-relative">
              <label class="required">Zustelldatum bis</label>
              <input class="form-control bg-transparent" type="text" formControlName="deliver_end_date"
                autocomplete="off" [matDatepicker]="deliverEndDate" />
              <div class="input-group-suffix">
                <mat-datepicker-toggle [for]="deliverEndDate"></mat-datepicker-toggle>
                <mat-datepicker #deliverEndDate></mat-datepicker>
              </div>
            </div>
          </div>
          <div class="col-4" *ngIf="!group.get('product')?.value?.endsWith('Direct')">
            <div class="fv-row mb-3">
              <label class="required">
                Zustelluhrzeit bis
              </label>
              <select class="form-select bg-transparent" data-control="select2" data-hide-search="true"
                formControlName="deliver_end_time">
                <option *ngFor="let slot of getTimeslots()" [value]="slot">{{ slot }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-4" *ngIf="!group.get('product')?.value?.endsWith('Direct')">
      <div class="fv-row mb-3 position-relative">
        <label class="required">
          Auktionsdauer
        </label>
        <input class="form-control bg-transparent" type="text" formControlName="offer_date" autocomplete="off"
          [matDatepicker]="offerDatePicker" />
        <div class="input-group-suffix">
          <mat-datepicker-toggle [for]="offerDatePicker" style="margin-top: -8px"></mat-datepicker-toggle>
          <mat-datepicker #offerDatePicker></mat-datepicker>
        </div>
      </div>
    </div>

    <hr />

    <div class="fv-row mb-3">
      <label class="required">
        Was wird versendet
      </label>
      <select class="form-select bg-transparent" data-control="select2" data-placeholder="Latest"
        data-hide-search="true" formControlName="what_send">
        <option [value]="typ.id" *ngFor="let typ of shipmentTypeList">{{ typ.name }}</option>
      </select>
    </div>

    <div class="fv-row mb-3">
      <label>
        Interne Referenz
      </label>
      <input class="form-control bg-transparent" type="text" formControlName="internal_reference" name="search"
        autocomplete="off" />
    </div>

    <div class="fv-row mb-3">
      <label class="form-label fs-6 fw-bolder text-dark">
        <span>Weitere Angaben, Bemerkungen, etc.</span>
      </label>
      <textarea class="form-control bg-transparent min-h-100px" rows="1" formControlName="additional_notes"></textarea>
    </div>

    <hr />

    <p>
      <b>Weitere Optionen</b>:
    </p>

    <div class="row">
      <div class="col-lg-4 col-6 mt-3">
        <div (click)="addOrRemoveOption('pickup_notification')" class="additional-container"
          [style.borderColor]="isActivated('pickup_notification')? '#7b213e' : '#eee'">
          <mat-icon>call</mat-icon>
          <div class="description" translate>Abholbenachrichtigung</div>
          <p *ngIf="getValueForAdditionalInformation('pickup_notification')?.price">
            {{ getValueForAdditionalInformation('pickup_notification').price.toFixed(2) }} &euro;
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-6 mt-3">
        <div (click)="addOrRemoveOption('delivery_notification')" class="additional-container"
          [style.borderColor]="isActivated('delivery_notification')? '#7b213e' : '#eee'">
          <mat-icon>call</mat-icon>
          <div class="description">Zustellbenachrichtigung</div>
          <p *ngIf="getValueForAdditionalInformation('delivery_notification')?.price">
            {{ getValueForAdditionalInformation('delivery_notification').price.toFixed(2) }} &euro;
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-6 mt-3">
        <div (click)="addOrRemoveOption('transportinsurance')" class="additional-container"
          [style.borderColor]="isActivated('transportinsurance') ? '#7b213e' : '#eee'">
          <mat-icon>healing</mat-icon>
          <div class="description">Transportversicherung</div>
          <p *ngIf="getValueForAdditionalInformation('transportinsurance')?.price">
            {{ getValueForAdditionalInformation('transportinsurance').price.toFixed(2) }} &euro;
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-6 mt-3">
        <div class="additional-container" (click)="addOrRemoveOption('dangerousgoods')"
          [style.borderColor]="isActivated('dangerousgoods')? '#7b213e' : '#eee'">
          <mat-icon>report</mat-icon>
          <div class="description">Gefahrgut</div>
          <p *ngIf="getValueForAdditionalInformation('dangerousgoods')?.price">
            {{ getValueForAdditionalInformation('dangerousgoods').price.toFixed(2) }} &euro;
          </p>

          <div *ngIf="getValueForAdditionalInformation('dangerousgoods')">
            <p>
              <b><span translate>Gefahrgutklasse</span>:</b> <span class="ms-2">{{
                getValueForAdditionalInformation('dangerousgoods').dangerous_goods_class
                }}</span>
            </p>
            <p>
              <b><span translate>Verpackungsgruppe</span>:</b> <span class="ms-2">{{
                getValueForAdditionalInformation('dangerousgoods').packaging_group }}</span>
            </p>
            <p>
              <b><span translate>UN Nummer</span>:</b> <span class="ms-2">{{
                getValueForAdditionalInformation('dangerousgoods').un_number }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-6 mt-3">
        <div class="additional-container" (click)="addOrRemoveOption('pallet_exchange')"
          [style.borderColor]="isActivated('pallet_exchange')? '#7b213e' : '#eee'">
          <mat-icon>swap_horiz</mat-icon>
          <div class="description" translate>Palettentausch</div>
          <p *ngIf="getValueForAdditionalInformation('pallet_exchange')?.price">
            {{ getValueForAdditionalInformation('pallet_exchange').price.toFixed(2) }} &euro;
          </p>


          <div *ngIf="getValueForAdditionalInformation('pallet_exchange')">
            <p>
              <b><span translate>Anzahl</span>:</b> <span
                class="ms-2">{{getValueForAdditionalInformation('pallet_exchange').qty }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-6 mt-3">
        <div (click)="addOrRemoveOption('timeframe')" class="additional-container"
          [style.borderColor]="isActivated('timeframe')? '#7b213e' : '#eee'">
          <mat-icon>calendar_today</mat-icon>

          <div class="description" translate *ngIf="isAnomymous">Zeitfensterbuchung</div>
          <div class="description" translate *ngIf="!isAnomymous">Fester Zustelltag</div>
          <p *ngIf="getValueForAdditionalInformation('timeframe')?.price">
            {{ getValueForAdditionalInformation('timeframe').price.toFixed(2) }} &euro;
          </p>
        </div>
      </div>
    </div>
  </div>
</form>