<div class="page-overlay-loader" *ngIf="(showLoader | async)">
    <span class="spinner-border text-primary"></span>
</div>

<div [hidden]="(showLoader | async)">
    <div class="card mb-5 mb-xl-10 p-10">
        <div class="d-flex flex-row">
            <div class="fv-row me-3 flex-fill">
                <label class="form-label fs-6 fw-bolder text-dark">Prozentsatz für Neukunden</label>
                <input class="form-control bg-transparent" type="text" [formControl]="discount" name="discount"
                    autocomplete="off" />
            </div>
            <button class="btn btn-primary btn-icon mt-8" (click)="saveDiscount()" [disabled]="showLoader | async">
                <app-keenicon name="soft-2" class="text-light"></app-keenicon>
            </button>
        </div>
    </div>
</div>