import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StatusHelperModule } from 'src/app/_metronic/shared/status.module';

@Component({
  selector: 'app-loading-kind-summary',
  templateUrl: './loading-kind-summary.component.html',
  styleUrls: ['./loading-kind-summary.component.scss']
})
export class LoadingKindSummaryComponent {

  constructor(
    public _status: StatusHelperModule) {
      this.loadingKindList = _status.getLoadingKinds();
      this.unloadingKindList = _status.getUnLoadingKinds();
    }

  loadingKindList: any = [];
  unloadingKindList: any = [];

  @Input()
  group: FormGroup;

}
