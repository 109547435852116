<form [formGroup]="group">
  <div formGroupName="vehicles">
    <mat-radio-group formControlName="vehicleType">
      <div class="row mt-4 mb-4">
        <div class="col-xl-3 col-lg-4 col-md-6 col-12 text-center border-right" *ngFor="let vehicle of vehicles; let i = index"
          (click)="selectType(i, vehicle.capacity, vehicle.ldm)">
          <div class="vehicle-card">
            <div class="full-width">
              <img [src]="vehicle.image" height="100" width="150" />
            </div>
            <p>
              <b><span>{{vehicle.short_name}}</span></b>
            </p>
            <mat-radio-button [value]="i" mode="md"></mat-radio-button>
          </div>
        </div>
      </div>
    </mat-radio-group>
  </div>
</form>