import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { defaultSwal } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss']
})
export class DiscountComponent {
  showLoader = new BehaviorSubject(false)
  discount: FormControl = new FormControl();
  constructor(private _http: HttpClient) {
    this.loadPreviousDiscount();
  }
  loadPreviousDiscount() {
    this.showLoader.next(true);
    this._http.get<any>(`${environment.api_url}/admin/discount`).subscribe(result => {
      console.log(result);

      this.discount.setValue(result.discount);
      this.showLoader.next(false);
    })
  }
  saveDiscount() {
    this.showLoader.next(true);
    const discountValue = this.discount.value;
    this._http.post<any>(`${environment.api_url}/admin/discount`, { value: discountValue }).subscribe(result => {
      this.showLoader.next(false);
      defaultSwal.fire({
        text: 'Neukundenrabatt gespeichert!',
        icon: 'success'
      })
    });
  }
}

