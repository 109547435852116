<form [formGroup]="group">
  <div class="row">
    <div class="col-6 deliverySummary" formGroupName="pickup">
      <h3 class="text-center">Abholung</h3>
      <div class="row">
        <div class="col-6">
          <div class="fv-row mb-3">
            <label class="form-label fs-6 fw-bolder text-dark">
              <span>Name</span>
            </label>
            <input class="form-control bg-transparent" type="text" formControlName="name" autocomplete="off" />
          </div>
        </div>
        <div class="col-6">
          <div class="fv-row mb-3">
            <label class="form-label fs-6 fw-bolder text-dark">
              <span>Firmenname</span>
            </label>
            <input class="form-control bg-transparent" type="text" formControlName="companyname" autocomplete="off" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <div class="fv-row mb-3">
            <label class="form-label fs-6 fw-bolder text-dark">
              <span class="required">Straße</span>
            </label>
            <input class="form-control bg-transparent" type="text" formControlName="street" autocomplete="off" />
          </div>
        </div>
        <div class="col-4">
          <div class="fv-row mb-3">
            <label class="form-label fs-6 fw-bolder text-dark">
              <span class="required">HausNr.</span>
            </label>
            <input class="form-control bg-transparent" type="text" formControlName="housenumber" autocomplete="off" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <div class="fv-row mb-3">
            <label class="form-label fs-6 fw-bolder text-dark">
              <span class="required">Postleitzahl</span>
            </label>
            <input class="form-control bg-transparent" type="text" formControlName="zipcode" autocomplete="off" />
          </div>
        </div>
        <div class="col-8">
          <div class="fv-row mb-3">
            <label class="form-label fs-6 fw-bolder text-dark">
              <span class="required">Stadt</span>
            </label>
            <input class="form-control bg-transparent" type="text" formControlName="city" autocomplete="off" />
          </div>
        </div>
      </div>
      <div class="fv-row mb-3">
        <label class="form-label fs-6 fw-bolder text-dark">
          <span class="required">Land</span>
        </label>
        <select class="form-select bg-transparent" data-control="select2" data-placeholder="Latest"
          data-hide-search="true" formControlName="country">
          <option [value]="country.code" *ngFor="let country of countryList">{{ country.name }}
          </option>
        </select>
      </div>
      <div class="fv-row mb-3">
        <label class="form-label fs-6 fw-bolder text-dark">
          <span>Abholreferenz</span>
        </label>
        <input class="form-control bg-transparent" type="text" formControlName="reference" autocomplete="off" />
      </div>
      <div class="fv-row mb-3">
        <label class="form-label fs-6 fw-bolder text-dark">
          <span>Kontakt</span>
        </label>
        <input class="form-control bg-transparent" type="text" formControlName="contact" autocomplete="off" />
      </div>
      <div class="fv-row mb-3">
        <label class="form-label fs-6 fw-bolder text-dark">
          <span>E-Mail</span>
        </label>
        <input class="form-control bg-transparent" type="text" formControlName="email" autocomplete="off" />
      </div>
      <div class="fv-row mb-3">
        <label class="form-label fs-6 fw-bolder text-dark">
          <span>Telefon</span>
        </label>
        <ngx-mat-intl-tel-input class="position-relative form-control bg-transparent"
          [preferredCountries]="['de', 'at']" [enablePlaceholder]="true" [enableSearch]="true"
          formControlName="phone"></ngx-mat-intl-tel-input>
      </div>
      <div class="fv-row mb-3">
        <label class="form-label fs-6 fw-bolder text-dark">
          <span>Weitere Angaben, Öffnungszeiten</span>
        </label>
        <textarea class="form-control bg-transparent min-h-100px" rows="1" formControlName="notes"></textarea>
      </div>
    </div>

    <div class="col-6 deliverySummary" formGroupName="deliver">
      <h3 class="text-center">Zustellung</h3>
      <div class="row">
        <div class="col-6">
          <div class="fv-row mb-3">
            <label class="form-label fs-6 fw-bolder text-dark">
              <span>Name</span>
            </label>
            <input class="form-control bg-transparent" type="text" formControlName="name" autocomplete="off" />
          </div>
        </div>
        <div class="col-6">
          <div class="fv-row mb-3">
            <label class="form-label fs-6 fw-bolder text-dark">
              <span>Firmenname</span>
            </label>
            <input class="form-control bg-transparent" type="text" formControlName="companyname" autocomplete="off" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          <div class="fv-row mb-3">
            <label class="form-label fs-6 fw-bolder text-dark">
              <span class="required">Straße</span>
            </label>
            <input class="form-control bg-transparent" type="text" formControlName="street" autocomplete="off" />
          </div>
        </div>
        <div class="col-4">
          <div class="fv-row mb-3">
            <label class="form-label fs-6 fw-bolder text-dark">
              <span class="required">HausNr.</span>
            </label>
            <input class="form-control bg-transparent" type="text" formControlName="housenumber" autocomplete="off" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <div class="fv-row mb-3">
            <label class="form-label fs-6 fw-bolder text-dark">
              <span class="required">Postleitzahl</span>
            </label>
            <input class="form-control bg-transparent" type="text" formControlName="zipcode" autocomplete="off" />
          </div>
        </div>
        <div class="col-8">
          <div class="fv-row mb-3">
            <label class="form-label fs-6 fw-bolder text-dark">
              <span class="required">Stadt</span>
            </label>
            <input class="form-control bg-transparent" type="text" formControlName="city" autocomplete="off" />
          </div>
        </div>
      </div>
      <div class="fv-row mb-3">
        <label class="form-label fs-6 fw-bolder text-dark">
          <span class="required">Land</span>
        </label>
        <select class="form-select bg-transparent" data-control="select2" data-placeholder="Latest"
          data-hide-search="true" formControlName="country">
          <option [value]="country.code" *ngFor="let country of countryList">{{ country.name }}
          </option>
        </select>
      </div>
      <div class="fv-row mb-3">
        <label class="form-label fs-6 fw-bolder text-dark">
          <span>Zustellreferenz</span>
        </label>
        <input class="form-control bg-transparent" type="text" formControlName="reference" autocomplete="off" />
      </div>
      <div class="fv-row mb-3">
        <label class="form-label fs-6 fw-bolder text-dark">
          <span>Kontakt</span>
        </label>
        <input class="form-control bg-transparent" type="text" formControlName="contact" autocomplete="off" />
      </div>
      <div class="fv-row mb-3">
        <label class="form-label fs-6 fw-bolder text-dark">
          <span>E-Mail</span>
        </label>
        <input class="form-control bg-transparent" type="text" formControlName="email" autocomplete="off" />
      </div>
      <div class="fv-row mb-3">
        <label class="form-label fs-6 fw-bolder text-dark">
          <span>Telefon</span>
        </label>
        <ngx-mat-intl-tel-input class="position-relative form-control bg-transparent"
          [preferredCountries]="['de', 'at']" [enablePlaceholder]="true" [enableSearch]="true"
          formControlName="phone"></ngx-mat-intl-tel-input>
      </div>

      <div class="fv-row mb-3">
        <label class="form-label fs-6 fw-bolder text-dark">
          <span>Weitere Angaben, Öffnungszeiten</span>
        </label>
        <textarea class="form-control bg-transparent min-h-100px" rows="1" formControlName="notes"></textarea>
      </div>
    </div>
  </div>
</form>