import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-vehicle-summary',
  templateUrl: './vehicle-summary.component.html',
  styleUrls: ['./vehicle-summary.component.scss']
})
export class VehicleSummaryComponent {

  @Input()
  group: FormGroup = new FormGroup({});

  selectType(carType: number, totalWeight: number, ldm: number) {
    this.group.get('vehicles')?.get('vehicleType')?.setValue(carType);
    this.group.get('vehicles')?.get('weight')?.setValue(totalWeight);
    this.group.get('vehicles')?.get('loading_meter')?.setValue(ldm);
  }

  vehicles: any[] = [
    {
      image: 'assets/media/trucks/sprinter.jpg',
      short_name: 'Planen-/Koffersprinter',
      length: 480,
      wide: 230,
      height: 220,
      capacity: 800,
      ldm: 4
    },
    {
      image: 'assets/media/trucks/truck_75.jpg',
      short_name: 'LKW 7,5t',
      length: 620,
      wide: 240,
      height: 240,
      capacity: 3000,
      ldm: 6.2
    },
    {
      image: 'assets/media/trucks/truck_12.jpg',
      short_name: 'LKW 12t',
      length: 720,
      wide: 240,
      height: 250,
      capacity: 5500,
      ldm: 7.2
    },
    {
      image: 'assets/media/trucks/sattel.jpg',
      short_name: 'Planensattel',
      length: 1360,
      wide: 240,
      height: 260,
      capacity: 24000,
      ldm: 13.6
    },
    {
      image: 'assets/media/trucks/sattel.jpg',
      short_name: 'Planensattel Mega',
      length: 1360,
      wide: 240,
      height: 300,
      capacity: 24000,
      ldm: 13.6
    },
    {
      image: 'assets/media/trucks/sattel.jpg',
      short_name: 'Kühltransporter Thermo',
      length: 1360,
      wide: 240,
      height: 265,
      capacity: 24000,
      ldm: 13.6
    },
    {
      image: 'assets/media/trucks/sattel.jpg',
      short_name: 'Koffer-Sattel',
      length: 1360,
      wide: 240,
      height: 265,
      capacity: 24000,
      ldm: 13.6
    },
    {
      image: 'assets/media/trucks/truck_collmulde.png',
      short_name: 'LKW-Coilmulde',
      length: 1360,
      wide: 240,
      height: 265,
      capacity: 24000,
      ldm: 13.6
    },
    {
      image: 'assets/media/trucks/long_truck.png',
      short_name: 'LKW-Gliederzug',
      length: 765,
      doubled: true,
      wide: 240,
      height: 265,
      capacity: 24000,
      ldm: 15
    },
    {
      image: 'assets/media/trucks/heavy_truck.png',
      short_name: 'Offener-LKW',
      length: 1360,
      wide: 240,
      height: 265,
      capacity: 24000,
      ldm: 13.6
    },
    {
      image: 'assets/media/trucks/open_lkw.png',
      short_name: 'Tieflader Schwertransporter',
      length: 2300,
      wide: 300,
      height: 340,
      capacity: 41800,
      ldm: '-'
    }
  ]

}
