import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({})
export class StatusHelperModule {
  private statusList = [
    {
      id: 'status_direct_created',
      name: 'Auftrag erstellt',
      cssClass: 'badge badge-info',
      product: ['direct']
    },
    {
      id: 'status_direct_delivered',
      name: 'Zugestellt',
      cssClass: 'badge badge-success',
      product: ['direct']
    },
    {
      id: 'status_direct_done',
      name: 'Abgeschlossen',
      cssClass: 'badge badge-success',
      product: ['direct']
    },
    {
      id: 'status_direct_in_dispo',
      name: 'In Disposition',
      cssClass: 'badge badge-primary',
      product: ['direct']
    },
    {
      id: 'payment_complete',
      name: 'Zahlung erfolgreich',
      cssClass: 'badge badge-success',
      product: ['direct']
    },
    {
      id: 'payment_open',
      name: 'Zahlung ausstehend',
      cssClass: 'badge badge-warning',
      product: ['direct']
    },
    {
      id: 'status_auction_draft',
      name: 'Auktion Entwurf',
      cssClass: 'badge badge-warning',
      product: ['auction']
    },
    {
      id: 'status_auction_no_offers',
      name: 'Keine Gebote',
      cssClass: 'badge badge-info',
      product: ['auction']
    },
    {
      id: 'status_auction_offers',
      name: 'Gebote abgegeben',
      cssClass: 'badge badge-info',
      product: ['auction']
    },
    {
      id: 'status_auction_open',
      name: 'Auktion gestartet',
      cssClass: 'badge badge-info',
      product: ['auction']
    },
    {
      id: 'status_auction_supplier_set',
      name: 'Zuschlag erteilt',
      cssClass: 'badge badge-success',
      product: ['auction']
    },
    {
      id: 'status_auction_expired',
      name: 'Auktion abgelaufen',
      cssClass: 'badge badge-danger',
      product: ['auction']
    },
    {
      id: 'status_auction_cancelled_free',
      name: 'Kostenlose Stornierung',
      cssClass: 'badge badge-danger',
      product: ['direct', 'auction']
    },
    {
      id: 'status_auction_cancelled_paid',
      name: 'Kostenpflichtige Stornierung',
      cssClass: 'badge badge-danger',
      product: ['direct', 'auction']
    },
    {
      id: 'status_anonymous_accepted',
      name: 'Angebot angenommen',
      cssClass: 'badge badge-info',
      product: ['anonymous']
    },
    {
      id: 'status_anonymous_declined',
      name: 'Angebot abgelehnt',
      cssClass: 'badge badge-danger',
      product: ['anonymous']
    },
    {
      id: 'status_anonymous_draft',
      name: 'Anfrage Entwurf',
      cssClass: 'badge badge-warning',
      product: ['anonymous']
    },
    {
      id: 'status_anonymous_expired',
      name: 'Angebot abgelaufen',
      cssClass: 'badge badge-warning',
      product: ['anonymous']
    },
    {
      id: 'status_anonymous_created',
      name: 'Angebot veröffentlicht',
      cssClass: 'badge badge-success',
      product: ['anonymous']
    },
    {
      id: 'status_anonymous_offer',
      name: 'Angebot erstellt',
      cssClass: 'badge badge-success',
      product: ['anonymous']
    },
    {
      id: 'status_anonymous_renegotiate',
      name: 'Angebot nachverhandeln',
      cssClass: 'badge badge-secondary',
      product: ['anonymous']
    },
    {
      id: 'status_anonymous_no_offers',
      name: 'Keine Angebote',
      cssClass: 'badge badge-info',
      product: ['anonymous']
    },
  ];

  constructor(private _sanitize: DomSanitizer) { }

  public getLoadingKinds() {
    return [
      {
        id: 'loading_kind_sender',
        name: 'Beladung über Laderampen durch Versender'
      }, {
        id: 'loading_kind_side',
        name: 'Beladung von der Seite durch Versender'
      }, {
        id: 'loading_kind_top',
        name: 'Beladung mit Hebebühne & Hubwagen durch Fahrer'
      }, {
        id: 'loading_by_sender',
        name: 'Beladung von der Seite durch Versender'
      }, {
        id: 'loading_top',
        name: 'Beladung von oben durch Versender'
      }, {
        id: 'loading_kind_crane',
        name: 'Beladung über Mitnahmekran durch Fahrer'
      }, {
        id: 'loading_kind_forklift',
        name: 'Beladung über Mitnahmestapler durch Fahrer'
      }
    ]
  }

  public getUnLoadingKinds() {
    return [
      {
        id: 'unloading_kind_sender',
        name: 'Entladung über Laderampen durch Versender'
      }, {
        id: 'unloading_kind_side',
        name: 'Entladung von der Seite durch Empfänger'
      }, {
        id: 'unloading_kind_top',
        name: 'Entladung nur über Laderampe aber mit Hebebühne & Hubwagen'
      }, {
        id: 'unloading_by_sender',
        name: 'Entladung von der Seite durch Versender'
      }, {
        id: 'unloading_top',
        name: 'Entladung von oben durch Empfänger'
      }, {
        id: 'unloading_kind_crane',
        name: 'Entladung über Mitnahmekran durch Fahrer'
      }, {
        id: 'unloading_kind_forklift',
        name: 'Entladung über Mitnahmestapler durch Fahrer'
      }, {
        id: 'unloading_lift',
        name: 'Entladung mit Hebebühne & Hubwagen durch Fahrer'
      }
    ]
  }

  public getShipmentType(kind: string) {
    switch (kind) {
      case "print_goods": return "Druckereierzeugnisse";
      case "machines": return "Anlagen / Maschinen / Ausrüstungen";
      case "industrial_goods": return "Industriegüter";
      case "consum_goods": return "Konsumgüter";
      case "land_and_forest": return "Erzeugnisse der Land- und Forstwirtschaft";
      case "car_goods": return "Güter für die Automobilindustrie";
      case "building_goods": return "Güter für die Bauindustrie";
      case "additional_goods": return "Sonstige Transportgüter";
    }
    return kind;
  }

  public getCratesType(kind: string) {
    switch (kind) {
      case "one_pallet": return "Einwegpalette";
      case "one_pallet_100": return "Einwegpalette 100";
      case "one_pallet_120": return "Einwegpalette 120";
      case "one_pallet_80": return "Einwegpalette 80";
      case "one_way_pallet": return "Einwegpalette";
      case "chep_euro_pallet": return "Chep Europalette";
      case "chep_part_pallet": return "Chep Halb-Palette";
      case "chep_quarter_pallet": return "Chep Viertel-Palette";
      case "dus_pallet": return "Düsseldorfer Pallette";
      case "euro_grid_box": return "Eurogitterbox";
      case "euro_pallet": return "Europalette";
      case "custom_crate": return "Benutzerdefiniert";
      case "euro_pallet_100": return "Europalette 100";
      case "euro_pallet_80": return "Europalette 80";
      case 'custom_crate': return "Benutzerdefiniert";
      case "colli": return "Colli";
      case "cp": return "CP";
      case "cp1": return "CP1";
      case "cp2": return "CP2";
      case "cp3": return "CP3";
      case "cp4": return "CP4";
      case "cp5": return "CP5";
      case "cp6": return "CP6";
      case "cp7": return "CP7";
      case "cp8": return "CP8";
      case "cp9": return "CP9";
      case "crate": return "Kiste";
      case "xl_package": return "XL Packet"
      case "ibc_case": return "IBC Behälter"
      case "EH1": return "EH1"
    }
    return kind;
  }

  public getPaymentMethod(paymentMethod: string) {
    switch (paymentMethod) {
      case 'invoice':
        return 'Rechnung';
      case 'stripe':
        return 'Stripe';
      case 'before':
        return 'Vorkasse';
      case 'no_paying_action_taken':
        return 'Stripe Zahlung abgelaufen'
      case 'stripe_progress':
        return 'Stripe in Bearbeitung'
      default:
        return paymentMethod;
    }
  }

  public getLoadingKindDescriptor(kind: string) {
    switch (kind) {
      case "unloading_kind_crane": return "Entladung über Mitnahmekran durch Fahrer";
      case "unloading_kind_forklift": return "Entladung über Mitnahmestapler durch Fahrer";
      case "unloading_kind_sender": return "Entladung über Laderampe durch Empfänger";
      case "unloading_kind_side": return "Entladung von der Seite durch Empfänger";
      case "unloading_kind_top": return "Entladung nur über Laderampe aber mit Hebebühne & Hubwagen";
      case "unloading_lift": return "Entladung mit Hebebühne & Hubwagen durch Fahrer";
      case "unloading_top": return "Entladung von oben durch Empfänger";
      case "loading_kind_crane": return "Beladung über Mitnahmekran durch Fahrer";
      case "loading_kind_forklift": return "Beladung über Mitnahmestapler durch Fahrer";
      case "loading_kind_sender": return "Beladung über Laderampen durch Versender";
      case "loading_kind_side": return "Beladung nur über Laderampe aber mit Hebebühne & Hubwagen ";
      case "loading_kind_top": return "Beladung mit Hebebühne & Hubwagen durch Fahrer";
      case "loading_top": return "Beladung von oben durch Versender";
    }
  }

  public getCancellationReason(kind: string) {
    switch (kind) {
      case "cancellation_logistic_changes_in_disposition": return "Änderungen in meiner Disposition liegen vor";
      case "cancellation_logistic_no_rest_times": return "Mein Fahrer kann seine Lenk- und Ruhezeiten nicht einhalten";
      case "cancellation_logistic_no_time": return "Ich kann das Abhol-/Zustelldatum nicht einhalten";
      case "no_options_available": return "Option ist für diesen Tarif nicht verfügbar.";
      case "not_enough_information": return "Ich habe unzureichende Informationen übermittelt";
      case "not_offered_dangerous": return "Ein Transport von Gefahrgut wird nicht angeboten.";
      case "not_offered_pallet_exchange": return "Ein Transport mit Palettentausch wird nicht angeboten.";
      case "changes_requested": return "Auftragsänderungen liegen vor.";
    }
    return kind;
  }

  public getStatusList(type: '' | 'direct' | 'anonymous' | 'auction' = '') {
    if (type) {
      return this.statusList.filter(obj => obj.product.includes(type));
    }
    return this.statusList;
  }

  public getStatusDescriptor(status: string) {
    const result = this.statusList.find(state => state.id == status);
    if (!result) {
      return '';
    }
    return this._sanitize.bypassSecurityTrustHtml(`<span class="${result.cssClass}">${result.name}</span>`);
  }
}
