import { Component } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DangerousGoodsDialogComponent } from '../dangerous-goods-dialog/dangerous-goods-dialog.component';

@Component({
  selector: 'app-pallet-exchange-dialog',
  templateUrl: './pallet-exchange-dialog.component.html',
  styleUrls: ['./pallet-exchange-dialog.component.scss']
})
export class PalletExchangeDialogComponent {
  group: FormGroup = new FormGroup({
    qty: new FormControl(null, [Validators.required, Validators.pattern(/[0-9]/)])
  });

  constructor(private _ref: MatDialogRef<DangerousGoodsDialogComponent>) { }

  ngOnInit() {}

  save() {
    this._ref.close(this.group.value);
  }
}
