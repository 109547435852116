import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-loading-meter-summary',
  templateUrl: './loading-meter-summary.component.html',
  styleUrls: ['./loading-meter-summary.component.scss']
})
export class LoadingMeterSummaryComponent {

  @Input()
  group: FormGroup;

  

}
