<form [formGroup]="getCratesGroup()">
  <div formArrayName="crates">
    <div class="row mt-4 pt-2" *ngFor="let crates of getControls(); let i = index" [formGroupName]="i">
      <div class="col-xl-2 col-lg-4 col-md-4 col-6 mt-1">
        <div class="fv-row mb-3">
          <label class="form-label fs-6 fw-bolder text-dark">
            <span class="required">Anzahl</span>
          </label>
          <input class="form-control bg-transparent" type="number" formControlName="quantity" autocomplete="off" />
        </div>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-4 col-6 mb-1 mt-1">
        <div class="fv-row mb-3">
          <label class="form-label fs-6 fw-bolder text-dark">
            <span class="required">Verpackung</span>
          </label>
          <select class="form-select bg-transparent" data-control="select2" data-placeholder="Latest"
            data-hide-search="true" (change)="updateLengthAndWidth($event, i)" formControlName="packaging">
            <option *ngFor="let option of options" [value]="option.id">{{ option.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-4 col-6 mb-1 mt-1">
        <div class="fv-row mb-3">
          <label class="form-label fs-6 fw-bolder text-dark">
            <span class="required">Länge</span>
          </label>
          <input class="form-control bg-transparent" type="number" formControlName="length" autocomplete="off" />
        </div>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-4 col-6 mb-1 mt-1">
        <div class="fv-row mb-3">
          <label class="form-label fs-6 fw-bolder text-dark">
            <span class="required">Breite</span>
          </label>
          <input class="form-control bg-transparent" type="number" formControlName="wide" autocomplete="off" />
        </div>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-4 col-6 mb-2 mt-1">
        <div class="fv-row mb-3">
          <label class="form-label fs-6 fw-bolder text-dark">
            <span class="required">Höhe</span>
          </label>
          <input class="form-control bg-transparent" type="number" formControlName="height" autocomplete="off" />
        </div>
      </div>
      <div class="col-xl-2 col-lg-4 col-md-4 col-6 mb-1">
        <div class="row pt-1">
          <div [ngClass]="getControls().length > 1 ? 'col-8' : 'col-12'">
            <div class="fv-row mb-3">
              <label class="form-label fs-6 fw-bolder text-dark">
                <span class="required">Gewicht</span>
              </label>
              <input class="form-control bg-transparent" type="number" formControlName="weight" autocomplete="off" />
            </div>
          </div>
          <div class="col-md-4 col-6" *ngIf="getControls().length > 1">
            <button class="btn btn-icon" (click)="removeOrClearCrates(i)">
              <mat-icon>remove-circle-outline</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-6">
        <!-- <ion-item lines="none">
          <ion-toggle slot="start" formControlName="stackable"></ion-toggle>
          <ion-label>
            <span translate>stackable_loadable</span>
            <ion-icon [matTooltip]="(individual ? 'stackable_tooltip_individual' : 'stackable_tooltip') | translate"
              class="ms-2" name="information-circle-outline"></ion-icon>
          </ion-label>
        </ion-item> -->
      </div>
    </div>
  </div>
</form>

<div class="row mt-2">
  <div class="col-lg-4 col-12">
    <button class="btn btn-secondary me-3 full-width" (click)="addCrateGroup()">
      <mat-icon>add-circle-outline</mat-icon>
      <label>Verpackung hinzufügen</label>
    </button>
    <button class="btn btn-secondary full-width" *ngIf="getControls().length > 1" (click)="removeOrClearCrates()">
      <mat-icon>remove-circle-outline</mat-icon>
      <label>Verpackung entfernen</label>
    </button>
  </div>
  <div class="col-lg-8 col-12 text-center">
    <p translate>Gesamtgewicht</p>
    <hr />

    <div class="row">
      <div class="col-4">
        <b translate>Verpackungen</b><br />
        <span>{{ package_data.packages }}</span>
      </div>
      <div class="col-4">
        <b translate>Lademeter

        </b><br />
        <span>{{ (package_data.loading_meter).toFixed(2) }} ldm</span>
      </div>
      <div class="col-4">
        <b translate>Gesamtgewicht</b><br />
        <span>{{ package_data.total_weight }} kg</span>
      </div>
    </div>
  </div>
</div>