import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { StatusHelperModule } from 'src/app/_metronic/shared/status.module';
import { DangerousGoodsDialogComponent } from './dangerous-goods-dialog/dangerous-goods-dialog.component';
import { PalletExchangeDialogComponent } from './pallet-exchange-dialog/pallet-exchange-dialog.component';

@Component({
  selector: 'app-additional-options-summary',
  templateUrl: './additional-options-summary.component.html',
  styleUrls: ['./additional-options-summary.component.scss']
})
export class AdditionalOptionsSummaryComponent {

  @Input()
  group: FormGroup;
  today = new Date();

  @Input()
  isAnomymous = false;

  shipmentTypeList = [
    { id: "print_goods", name: "Druckereierzeugnisse" },
    { id: "machines", name: "Anlagen / Maschinen / Ausrüstungen" },
    { id: "industrial_goods", name: "Industriegüter" },
    { id: "consum_goods", name: "Konsumgüter" },
    { id: "land_and_forest", name: "Erzeugnisse der Land- und Forstwirtschaft" },
    { id: "car_goods", name: "Güter für die Automobilindustrie" },
    { id: "building_goods", name: "Güter für die Bauindustrie" },
    { id: "additional_goods", name: "Sonstige Transportgüter" }
  ];

  getDirectTimeslots() {
    if (this.group.get('selectedTab')?.value == 2) {
      return ['07:00 - 16:00', '08:00 - 12:00', '08:00 - 17:00', '06:00 - 14:00', '09:00 - 15:00', '11:00 - 17:30', '16:00 - 19:00']
    }
    if (this.group.get('selectedTab')?.value == 1) {
      return ['07:00 - 16:00', '08:00 - 17:00', '06:00 - 14:00', '09:00 - 15:00', '11:00 - 17:30'];
    }
    const selectedPlan = this.group.get('vehicles')?.get('vehicle_type')?.value;
    if (selectedPlan == 0 || selectedPlan == 2) {
      return ['07:00 - 16:00', '08:00 - 17:00', '06:00 - 14:00', '09:00 - 15:00', '11:00 - 17:30'];
    } else {
      return ['07:00 - 16:00', '08:00 - 12:00', '08:00 - 17:00', '06:00 - 14:00', '09:00 - 15:00', '11:00 - 17:30', '16:00 - 19:00'];
    }
    return [];
  }

  getTimeslots() {
    const timeslots = [];
    for (let i = 7; i < 24; i++) {
      let prefix = String(i);
      if (i < 10) {
        prefix = `0${i}`;
      }
      timeslots.push(`${prefix}:00`);
    }
    for (let i = 0; i < 7; i++) {
      let prefix = String(i);
      if (i < 10) {
        prefix = `0${i}`;
      }
      timeslots.push(`${prefix}:00`);
    }
    return timeslots;
  }

  addOrRemoveOption(option: string) {
    const additional_options = this.group.get('additional_information')?.get('additional_options')?.value;
    const filtered = additional_options.filter((object: any) => object.name == option);
    if (filtered.length > 0) {
      const index = additional_options.findIndex((value: any) => value.name == option);
      additional_options.splice(index, 1)
    } else {
      if (option == 'pallet_exchange') {
        const dialogRef = this._dialog.open(PalletExchangeDialogComponent, { width: '600px' });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            additional_options.push({
              name: option,
              price: 10.0,
              qty: result.qty
            });
            this.group.get('additional_information')?.get('additional_options')?.setValue(additional_options);
            setTimeout(() => {
              this._ref.detectChanges();
            }, 400);
          }
        });

      } else if (option == 'dangerousgoods') {
        const dialogRef = this._dialog.open(DangerousGoodsDialogComponent, { width: '600px' });
        dialogRef.afterClosed().subscribe(result => {          
          if (result.un_number) {
            additional_options.push({
              name: option,
              price: 10.0,
              dangerous_goods_class: result.dangerous_goods_class,
              packaging_group: result.packaging_group,
              un_number: result.un_number
            });
            this.group.get('additional_information')?.get('additional_options')?.setValue(additional_options);
            setTimeout(() => {
              this._ref.detectChanges();
            }, 100);
          }
        });
      } else {
        additional_options.push({
          name: option,
          price: 10.0
        });
        this.group.get('additional_information')?.get('additional_options')?.setValue(additional_options);
      }
    }
  }

  isActivated(option: string) {
    const additional_options = this.group.get('additional_information')?.get('additional_options')?.value;
    if (additional_options.findIndex((object: any) => object.name == option) != -1) {
      return true;
    }
    return false;
  }

  getValueForAdditionalInformation(option: string): any {
    const options = this.group.get('additional_information')?.value;
    const filtered: any = options.additional_options.filter((optionObject: any) => optionObject.name == option)
    if (filtered.length == 1) {
      return filtered[0];
    }
    return null;
  }

  max(value: number, max: number): number {
    if (value > max) {
      return max;
    }
    return value;
  }

  constructor(public _status: StatusHelperModule,
    private _dialog: MatDialog,
    private _ref: ChangeDetectorRef) { }
}
