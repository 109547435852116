<form [formGroup]="group">
  <div class="row">
    <div class="col-6">
      <div class="fv-row mb-3">
        <label class="form-label fs-6 fw-bolder text-dark">
          <span class="required">Beladeart</span>
        </label>
        <select class="form-select bg-transparent" data-control="select2"
          data-placeholder="Latest" data-hide-search="true" formControlName="loading_kind">
          <option [value]="kind.id" *ngFor="let kind of loadingKindList">{{ kind.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-6">
      <div class="fv-row mb-3">
        <label class="form-label fs-6 fw-bolder text-dark">
          <span class="required">Entladeart</span>
        </label>
        <select class="form-select bg-transparent" data-control="select2"
          data-placeholder="Latest" data-hide-search="true" formControlName="unloading_kind">
          <option [value]="kind.id" *ngFor="let kind of unloadingKindList">{{ kind.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</form>
