import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

export function createNewCrateFormGroup(): FormGroup {
  return new FormGroup({
    'quantity': new FormControl('1', Validators.required),
    'packaging': new FormControl(null, Validators.required),
    'length': new FormControl('120', [Validators.required, Validators.min(0.1)]),
    'wide': new FormControl('80', [Validators.required, Validators.min(0.1)]),
    'height': new FormControl('100', [Validators.required]),
    'weight': new FormControl('300', [Validators.required]),
    'stackable': new FormControl(null)
  });
}

@Component({
  selector: 'app-crates-summary',
  templateUrl: './crates-summary.component.html',
  styleUrls: ['./crates-summary.component.scss']
})
export class CratesSummaryComponent implements OnInit {



  ngOnInit(): void {
    this.group.get('crates')?.get('crates')?.valueChanges.subscribe(() => {
      this.calculateTotal();
    });
    this.calculateTotal();
  }

  @Input()
  group: FormGroup;

  options = [
    {
      id: 'custom_crate',
      length: 110,
      width: 80,
      name: "Benutzerdefiniert"
    },
    {
      id: 'euro_pallet',
      length: 120,
      width: 80,
      name: "Europalette"
    },
    {
      id: 'euro_pallet_80',
      name: 'Europalette 80',
      length: 120,
      width: 80
    },
    {
      id: 'euro_pallet_100',
      name: 'Europalette 100',
      length: 120,
      width: 100
    },
    {
      id: 'one_pallet_80',
      name: 'Einwegpalette 80',
      length: 120,
      width: 80
    },
    {
      id: 'one_pallet',
      name: 'Einwegpalette',
      length: 120,
      width: 80
    },
    {
      id: 'one_pallet_120',
      name: 'Einwegpalette 120',
      length: 120,
      width: 120
    },
    {
      id: 'one_pallet_100',
      name: 'Einwegpalette 100',
      length: 120,
      width: 100
    },
    {
      id: 'chep_euro_pallet',
      name: 'Chep Europalette',
      length: 120,
      width: 80
    },
    {
      id: 'chep_part_pallet',
      name: 'Chep Halb-Palette',
      length: 76,
      width: 51
    },
    {
      id: 'chep_quarter_pallet',
      name: 'Chep Viertel-Palette',
      length: 60,
      width: 40
    },
    {
      id: 'euro_grid_box',
      name: 'Eurogitterbox',
      length: 124,
      width: 84
    },
    {
      id: 'dus_pallet',
      name: 'Düsseldorfer Pallette',
      length: 80,
      width: 60
    },
    {
      id: 'cp1',
      name: 'CP1',
      length: 120,
      width: 100
    },
    {
      id: 'cp2',
      name: 'CP2',
      length: 120,
      width: 80
    },
    {
      id: 'cp3',
      name: 'CP3',
      length: 114,
      width: 114
    },
    {
      id: 'cp4',
      name: 'CP4',
      length: 130,
      width: 110
    },
    {
      id: 'cp5',
      name: 'CP5',
      length: 114,
      width: 76
    },
    {
      id: 'cp6',
      name: 'CP6',
      length: 120,
      width: 100
    },
    {
      id: 'cp7',
      name: 'CP7',
      length: 130,
      width: 110
    },
    {
      id: 'cp8',
      name: 'CP8',
      length: 114,
      width: 114
    },
    {
      id: 'cp9',
      name: 'CP9',
      length: 114,
      width: 114
    },
    {
      id: 'eh1',
      name: 'EH1',
      length: 120,
      width: 80
    },
    {
      id: 'ibc_case',
      name: 'EBC Container',
      length: 120,
      width: 110
    },
    {
      id: 'xl_package',
      name: 'XL Paket',
      length: 80,
      width: 40,
      height: 30,
    },
    {
      id: 'l_package',
      name: 'L Paket',
      length: 60,
      width: 38,
      height: 25,
    },
    {
      id: 'm_package',
      name: 'M Paket',
      length: 45,
      width: 30,
      height: 20,
    },
    {
      id: 's_package',
      name: 'S Paket',
      length: 35,
      width: 20,
      height: 10,
    },
    {
      id: 'xs_package',
      name: 'XS Paket',
      length: 28,
      width: 20,
      height: 5,
    },
  ]

  package_data = {
    packages: 1,
    loading_meter: 0.4,
    total_weight: 300
  };

  private calculateTotal() {
    this.package_data = {
      packages: 0,
      loading_meter: 0,
      total_weight: 0
    };


    (this.group.get('crates')?.get('crates') as FormArray).controls.forEach(controls => {
      if (controls) {
        const packages = parseInt(controls.get('quantity')!.value, 10),
          loading_meter = ((controls.get('length')!.value / 100) * (controls.get('wide')!.value / 100) / 2.4) * Number(controls.value.quantity),
          weight = parseInt(controls.get('weight')!.value, 10) * Number(controls.value.quantity);
        this.package_data.packages += isNaN(packages) ? 0 : packages;
        this.package_data.loading_meter += isNaN(loading_meter) ? 0 : loading_meter;
        this.package_data.total_weight += isNaN(weight) ? 0 : weight;
      }
    });
    this.group.get('crates')?.get('loading_meter')?.setValue(this.package_data.loading_meter)
    this.group.get('crates')?.get('weight')?.setValue(this.package_data.total_weight);
    this.group.get('totalLdm')?.setValue(this.package_data.loading_meter);
    this.group.get('totalWeight')?.setValue(this.package_data.total_weight);
  }

  public addCrateGroup() {
    const crate = this.group.get('crates')?.get('crates') as FormArray;
    crate.push(createNewCrateFormGroup());
  }

  public removeOrClearCrates(i: any = null) {
    const crates = this.group.get('crates')?.get('crates') as FormArray;
    if (crates.length > 1) {
      if (i) {
        crates.removeAt(i);
        return;
      }
      crates.removeAt(crates.length - 1);
    } else {
      crates.reset();
    }
  }

  getControls() {
    return (this.group.get('crates')?.get('crates') as FormArray).controls;
  }

  getCratesGroup() {
    return this.group.get('crates') as FormGroup;
  }

  updateLengthAndWidth($event: any, index: number) {
    const value = $event.target.value;
    const selectedOption = this.options.filter((option: any) => option.id === value);
    const fg: any = (this.group.get('crates')?.get('crates') as FormArray).at(index) as FormGroup;

    fg.get('length').setValue(selectedOption[0].length);
    fg.get('wide').setValue(selectedOption[0].width);
    if (selectedOption[0].height) {
      fg.get('height').setValue(selectedOption[0].height);
    }
    this.calculateTotal();
  }
}
